import {Col, Layout, Menu, Row} from "antd";
import logo from "../321CQU.png";
import React, {useCallback, useEffect, useState} from "react";
import {getAvatarApi, LocalUrl} from "../utils/api";
import {Outlet, useOutletContext, Link} from "react-router-dom";
import {getAuthority, UserInfoManager, UserStatue} from "../model/userInfo";
import {UserInfoShower} from "../components/userInfoComponents/userInfoShower";
import {LoginButton} from "../components/loginComponents/loginButton";
import {handleWebsiteRequest, handleWebsiteResponse} from "../utils/tools";
import {AnnouncementData, AnnouncementListItem} from "../model/announcement";

const { Header, Footer, Content } = Layout

const menu_items = [
    {
        key: 'index',
        label: <a href={LocalUrl.homepage}>首页</a>
    },
    {
        key: 'examine',
        label: <a href={LocalUrl.examine}>推荐位审核</a>
    },
]

export const BasePage: React.FC = (props) => {
    const [userStatue, setUserStatue] = useState(UserInfoManager.statue)
    const [announcementList, setAnnouncementList] = useState<AnnouncementListItem[]>([])
    const [recentAnnouncement, setRecentAnnouncement] = useState<AnnouncementData>({type: "add", title: null})

    const outletContext: MyOutletContextType = {
        announcementList: [announcementList, setAnnouncementList],
        recentAnnouncement: [recentAnnouncement, setRecentAnnouncement],
        userStatue: [userStatue, setUserStatue]
    }

    const getMenuItems = useCallback(() => {
        if (userStatue.info.authority === 'super' || userStatue.info.authority === 'auditor') {
            return menu_items
        } else {
            return menu_items.slice(undefined, 1)
        }
    }, [userStatue.info.authority])

    useEffect(() => {
        if (!userStatue.isLogin) {return}
        (
            async () => {
                await getAuthority((_) => setUserStatue({...UserInfoManager.statue}))
            }
        )()
    }, [userStatue.isLogin])

    return (
        <Layout className="layout">
            <Header>
                <Row wrap={false} justify={"center"}>
                    <Col flex={"none"}>
                        <Link to={LocalUrl.homepage}>
                            <img src={logo} height={"50vmin"} alt={"321CQU logo"}/>
                        </Link>
                    </Col>

                    <Col flex={"0 0 200px"}>
                        <Menu theme={"dark"} mode="horizontal" items={getMenuItems()}/>
                    </Col>

                    <Col flex={"auto"} style={{textAlign: "end"}}>
                        {
                            userStatue.isLogin ?
                                <UserInfoShower
                                    userStatue={userStatue}
                                    onUserInfoChange={
                                        (newInfo) => {
                                            setUserStatue({...UserInfoManager.statue})
                                        }
                                    }
                                    onLogout={
                                        () => {
                                            UserInfoManager.logout()
                                            setUserStatue({...UserInfoManager.statue})
                                        }
                                    }
                                    setAvatar={
                                        (path) => {
                                            UserInfoManager.statue.info.avatarUrl = path
                                            setUserStatue({...UserInfoManager.statue})
                                        }
                                    }
                                />
                                :
                                <LoginButton
                                    afterLogin={
                                        async () => {
                                            let res = await handleWebsiteRequest(getAvatarApi,
                                                {Params: {Name: UserInfoManager.statue.info.groupName}, Version: '2.0'},
                                                false)

                                            await handleWebsiteResponse(res, (result) => {
                                                UserInfoManager.statue.info.avatarUrl = result.data.Avatar
                                            })
                                            UserInfoManager.statue.isLogin = true
                                            UserInfoManager.statue.save()
                                            setUserStatue({...UserInfoManager.statue})
                                        }
                                    }
                                />
                        }
                    </Col>
                </Row>
            </Header>
            <Content className={"mainContent"}>
                <Outlet context={outletContext}/>
            </Content>
            <Footer>
                <div style={{textAlign: 'center'}}>
                    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">赣ICP备2021010721号-2</a>
                </div>
            </Footer>
        </Layout>
    )
}

interface MyOutletContextType {
    userStatue: [UserStatue, React.Dispatch<React.SetStateAction<UserStatue>>]
    announcementList: [AnnouncementListItem[], React.Dispatch<React.SetStateAction<AnnouncementListItem[]>>]
    recentAnnouncement: [AnnouncementData, React.Dispatch<React.SetStateAction<AnnouncementData>>]
}

export function useMyOutletContext() {
    return useOutletContext<MyOutletContextType>();
}
