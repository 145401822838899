import {Button, Empty, message, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import {launchAnnouncementColumn} from "./launchAnnouncementColumn";
import {AnnouncementListItem} from "../../../model/announcement";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../../utils/tools";
import {
    AnnouncementUrl,
    changeAnnouncementPublishStatueApi,
    getAnnouncementListApi, LocalUrl,
    updateAnnouncementApi
} from "../../../utils/api";
import {useMyOutletContext} from "../../../pages/basePage";
import {useNavigate} from "react-router";

interface AnnouncementTableProps {
    onAdd: () => void
}

export const AnnouncementTable: React.FC<AnnouncementTableProps> = (props) => {
    const {userStatue: [userStatue,]} = useMyOutletContext()
    const {announcementList: [announcements, setAnnouncements]} = useMyOutletContext()
    const {recentAnnouncement: [, setAnnouncement]} = useMyOutletContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const getAnnouncementList = async () => {
        if (userStatue.isLogin) {
            let res = await handleWebsiteRequest(getAnnouncementListApi, {})

            await handleWebsiteResponse(res, (response) => {
                let announce = response.Announcements as AnnouncementListItem[]
                setAnnouncements(announce.map((value) => {
                    return  {...value, CoverUrl: value.CoverUrl === 'null' ? null : value.CoverUrl} as AnnouncementListItem
                }))
            }, (_) => {
                message.warning("参数错误")
            }, (_) => {
                message.warning("服务器异常")
            })
        } else {
            setAnnouncements([])
        }
    }

    const refreshAnnouncementList: () => void = () => {
        (
            async () => {
                await getAnnouncementList()
            }
        )()
    }

    useEffect(() => {
        setLoading(true)
        refreshAnnouncementList()
        setLoading(false)
    }, [userStatue.isLogin])

    const changePublishStatue = async (id: number, needPublish: number) => {
        const value = {
            'Id': id,
            'NeedPublish': needPublish,
        }

        let res = await handleWebsiteRequest(changeAnnouncementPublishStatueApi, value)

        await handleWebsiteResponse(res, (_) => {
            message.success(needPublish ? '发布成功' : '撤回成功')
            refreshAnnouncementList()
        }, (_) => {
            message.warning('无权限修改')
        }, (_) => {
            message.error('服务器异常')
        })
    }

    const deleteAnnouncement = async (id: number) => {
        const value = {
            'Id': id,
            'IsDelete': true,
        }

        let res = await handleWebsiteRequest(updateAnnouncementApi, value)

        await handleWebsiteResponse(res, (_) => {
            message.success('删除成功')
            refreshAnnouncementList()
        }, (_) => {
            message.warning('无权限删除')
        }, (_) => {
            message.error('服务器异常')
        })
    }


    return (
        announcements.length ?
            <Table dataSource={announcements.slice(0, 10)} pagination={false} scroll={{x: true}}
                   columns={launchAnnouncementColumn({
                       onWithdraw: (aid: number) => changePublishStatue(aid, 0),
                       onPublish: (aid: number) => changePublishStatue(aid, 1),
                       onChange: (record: AnnouncementListItem) => {
                           setAnnouncement({
                               aid: record.Aid,
                               coverUrl: record.CoverUrl,
                               endDate: record.EndDate,
                               startDate: record.StartDate,
                               title: record.Title,
                               type: "update"
                           })
                           navigate(LocalUrl.announcement + AnnouncementUrl.update)
                       },
                       onDelete: (aid: number) => deleteAnnouncement(aid),
                       showDetail: false,
                   })}
            /> :
            <Empty
                description={
                    <span>
                        暂无活动公告
                    </span>
                }
                style={{margin: "24px"}}
            >
                <Button type={"primary"} onClick={props.onAdd}>
                    创建新公告
                </Button>
                <Spin spinning={loading} tip={"加载中..."}/>
            </Empty>
    )
}