import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BasePage} from "./pages/basePage";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {HomePage} from "./pages/homePage";
import {AnnouncementUrl, LocalUrl} from "./utils/api";
import {MarkdownPage} from "./pages/markdownPage";
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {ExaminePage} from "./pages/ExaminePage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
moment.locale('zh-cn')
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <ConfigProvider locale={zhCN}>
              <Routes>
                  <Route path={""} element={<BasePage />}>
                        <Route path={LocalUrl.homepage} element={<HomePage />}/>
                        <Route path={LocalUrl.announcement}>
                            <Route path={AnnouncementUrl.add.slice(1)} element={<MarkdownPage />}/>
                            <Route path={AnnouncementUrl.update.slice(1)} element={<MarkdownPage />}/>
                        </Route>
                        <Route path={LocalUrl.examine} element={<ExaminePage />}/>
                  </Route>

              </Routes>
          </ConfigProvider>
      </BrowserRouter>
  </React.StrictMode>
);
