import {Moment} from "moment/moment";

export interface RecommendApplyListItem {
    key: number,
    RAid: number,
    Aid: number,
    Position: number,
    StartDate: string,
    EndDate: string,
    GroupName?: string,
    Title: string,
    ImgPath?: string,
    State: number,
    Comments: string | null,
    UpdateTime: string,
}

export interface RecommendApplyParams {
    Aid: number | null,
    Position: number | null,
    StartDate: string | null,
    EndDate: string | null,
    Path: string | null
}

export function calculateApplyOfDay(day: Moment, applyList: RecommendApplyListItem[]): number {
    let result: number = 0

    for (let apply of applyList) {
        if (apply.State === 1 && day.isBetween(apply.StartDate, apply.EndDate, 'day', '[]')) {
            result += 1
        }
    }

    return result
}