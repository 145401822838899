import {Api} from "./api";
import {UserInfoManager} from "../model/userInfo";
import type {UploadFile, UploadProps} from "antd";
import {message, Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";

export const handleWebsiteRequest = async (target: Api, params: Map<string, any>|Object, addUserInfo = true) => {
    let param: Map<string, any>;
    if (!(params instanceof Map)) {
        param = new Map(Object.entries(params))
    } else {
        param = params
    }
    param.set('Key', 'CQUz5321')

    if (addUserInfo) {
        param.set('Username', UserInfoManager.statue.info.username)
        param.set('Password', UserInfoManager.statue.info.password)
    }

    let headers: HeadersInit | undefined;
    if (target.method === "POST") {
        headers = {'Content-Type': 'application/json;charset=UTF-8'}
    }

    return await fetch(target.url, {
        method: target.method, body: JSON.stringify(Object.fromEntries(param)), credentials: 'include',
        headers
    })
}

export const handleWebsiteResponse = async (res: Response,
                                     onSuccess: (data: any) => void,
                                     onError?: (data: any) => void,
                                     onNetworkError?: (data: any) => void) => {
    if (res.ok) {
        let response = await res.json()
        if (response.Status || response.Statue) {
            onSuccess(response)
        } else {
            onError?.(response)
        }
    } else {
        onNetworkError?.(res)
    }
}

export function imgUploadToServerProps(name: string, url: string, afterSuccess: (file: UploadChangeParam<UploadFile<any>>) => void): UploadProps {
    return  {
        name: name,
        action: url,
        data: {
            Username: UserInfoManager.statue.info.username,
            Password: UserInfoManager.statue.info.password,
            Key: 'CQUz5321',
        },
        maxCount: 1,
        showUploadList: true,
        beforeUpload: (file) => {
            const isPNG = file.type.slice(0,5) === 'image';

            if (!isPNG) {
                message.error(`${file.name} 不是图像文件`);
            }

            return isPNG || Upload.LIST_IGNORE;
        },
        onChange: (info) => {
            if (info.file.status === 'done') {
                if (info.file.response.Status === 1) {
                    message.success("上传成功")
                    afterSuccess(info)
                } else {
                    message.error("上传失败，请重试，多次失败请尝试联系网站管理员")
                }
            }
            if (info.file.status === 'error') {
                message.warning("服务器异常")
            }
        },
    }
}
