import React, {useState} from "react";
import {UserInfo, UserStatue} from "../../model/userInfo";
import {Avatar, Button, Dropdown, Menu, Space, Tag} from "antd";
import {DownOutlined, EditOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {remoteHost} from "../../utils/api";
import {ChangeUserInfoModal} from "./changeUserInfoModal";

interface UserInfoShowerProps {
    userStatue: UserStatue
    onUserInfoChange: (newInfo: UserInfo) => void,
    onLogout: () => void,
    setAvatar: (path: string) => void
}

export const UserInfoShower: React.FC<UserInfoShowerProps> = (props) => {
    const [showChangeModal, setShowChangeModal] = useState(false)

    let userItems = [
        {
            key: 'changeUserInfo',
            label: (
                <Button icon={<EditOutlined />} type={"text"}
                        onClick={() => setShowChangeModal(true)}>
                    修改组织信息
                </Button>
            )
        },
        {
            key: 'logout',
            label: (
                <Button type={"text"} icon={<LogoutOutlined />} onClick={props.onLogout}>注销</Button>
            )
        }
    ]

    return (
        <>
            <Space style={{marginLeft: "auto"}}>
                {
                    props.userStatue.info.avatarUrl === null ?
                        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /> :
                        <Avatar src={remoteHost + props.userStatue.info.avatarUrl} />
                }
                <Tag>{props.userStatue.info.groupName}</Tag>
                <Dropdown overlay={<Menu items={userItems}/>} placement={"bottomLeft"}>
                    <DownOutlined style={{fontSize: '12px', color: '#08c'}}/>
                </Dropdown>
            </Space>
            <ChangeUserInfoModal open={showChangeModal} onCancel={() => setShowChangeModal(false)}
                                 onUserInfoChange={props.onUserInfoChange}  setAvatar={props.setAvatar}/>
        </>
    )
}