import React, {useState} from "react";
import {ExamineAppliesCalendar} from "../components/examineAppliesCalendar";
import {RecommendApplyListItem} from "../model/recommendApply";
import {useOutletContext} from "react-router-dom";
import {Button, Card, Col, Row} from "antd";
import {ExamineApplyTable} from "../components/tableComponents/examineApplyTableComponents/examineApplyTable";
import {MenuOutlined} from "@ant-design/icons";

export const ExaminePage: React.FC = (_) => {
    const [examineApplyList, setExamineApplyList] = useState<RecommendApplyListItem[]>([])
    const [selectDateRange, setSelectDateRange] = useState<[string, string] | null>(null)

    return (
        <Row gutter={24}>
            <Col span={6}>
                <ExamineAppliesCalendar  applyList={examineApplyList} selectDateRange={selectDateRange}/>
            </Col>

            <Col span={18}>
                <Card
                    title={"待审核申请"}
                    extra={<Button disabled icon={<MenuOutlined />} href={""}>更多</Button>}
                    style={{minHeight: 800}}
                >
                    <ExamineApplyTable  applyList={examineApplyList} setApplyList={setExamineApplyList}
                                        setSelectDateRange={setSelectDateRange}/>
                </Card>
            </Col>
        </Row>
    )
}

interface ExamineContext {
    examineApply: [RecommendApplyListItem[], React.Dispatch<React.SetStateAction<RecommendApplyListItem>[]>]
}

export function useExamineContext() {
    return useOutletContext<ExamineContext>()
}