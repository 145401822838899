import React, {useCallback} from "react";
import moment, {Moment} from "moment/moment";
import {Avatar, Badge, Calendar} from "antd";
import {calculateApplyOfDay, RecommendApplyListItem} from "../model/recommendApply";

interface ExamineAppliesCalendarProps {
    applyList: RecommendApplyListItem[]
    selectDateRange: [string, string] | null
}

export const ExamineAppliesCalendar: React.FC<ExamineAppliesCalendarProps> = (props) => {
    const dateCellRender = useCallback((value: Moment) => {
        let today = moment().startOf('day');
        let isToday = value.isSame(today, 'd');
        let isSameMonth = value.isSame(today, 'month');
        let isSelected = props.selectDateRange === null ?
            null : value.isBetween(props.selectDateRange[0], props.selectDateRange[1], 'day', '[]')

        return (
            <Badge size={"small"} count={calculateApplyOfDay(value, props.applyList)}>
                <Avatar
                    className={
                        isSelected ? "selectedDayCell" :
                            (isSameMonth ?
                                (isToday ? "todayCell" : "otherDayCell") : "diffMonthDayCell")
                    }
                    shape={"square"}>
                    {value.date()}
                </Avatar>
            </Badge>
        )
    }, [props.applyList, props.selectDateRange])

    return <Calendar fullscreen={false} dateFullCellRender={dateCellRender} />
}