export interface Api {
    url: string
    method: "POST" | "GET"
}

export const remoteHost = "//www.zhulegend.com"
export const mediaUrl = "//www.zhulegend.com/media"
const baseUrl = "//www.zhulegend.com/321CQUWebsite"
// const baseUrl = "//127.0.0.1:8000/321CQUWebsite"
const apiUrl = "//www.zhulegend.com/321CQU"
// const apiUrl = "//127.0.0.1:8000/321CQU"

export const enum LocalUrl {
    homepage = "/",
    announcement = "/announcement",
    examine = "/examine"
}

export const enum AnnouncementUrl {
    add = "/add",
    update = "/update"
}

export const enum MediaUrl {
    applyCover = "/applyCover/"
}

export const loginApi: Api = {
    url: baseUrl + "/user/login",
    method: "POST"
}

export const getAuthorityApi: Api = {
    url: baseUrl + "/user/get_authority",
    method: "POST"
}

export const changeUserInfoApi: Api = {
    url: baseUrl + "/user/change_info",
    method: "POST"
}

export const changeAvatarApi: Api = {
    url: baseUrl + "/user/change_avatar",
    method: "POST"
}

export const getAvatarApi: Api = {
    url: apiUrl + "/announcement/group/get_info",
    method: "POST"
}

export const getAnnouncementListApi: Api = {
    url: baseUrl + "/announcement/list",
    method: "POST"
}

export const changeAnnouncementPublishStatueApi: Api = {
    url: baseUrl + "/announcement/publish",
    method: "POST"
}

export const addAnnouncementApi: Api = {
    url: baseUrl + "/announcement/add",
    method: "POST"
}

export const updateAnnouncementApi: Api = {
    url: baseUrl + "/announcement/update",
    method: "POST"
}

export const getAnnouncementApi: Api = {
    url: baseUrl + "/announcement/get",
    method: "POST"
}

export const uploadBannerCoverApi: Api = {
    url: baseUrl + "/announcement/apply/upload_cover",
    method: "POST"
}

export const getRecommendApplyApi: Api = {
    url: baseUrl + "/announcement/apply/get",
    method: "POST"
}

export const addRecommendApplyApi: Api = {
    url: baseUrl + "/announcement/apply/add",
    method: "POST"
}

export const deleteRecommendApplyApi: Api = {
    url: baseUrl + "/announcement/apply/delete",
    method: "POST"
}

export const getExamineApplyApi: Api = {
    url: baseUrl + "/announcement/examine/get",
    method: "POST"
}

export const examineApplyApi: Api = {
    url: baseUrl + "/announcement/examine/set",
    method: "POST"
}

export const getCosCredential: Api = {
    url: apiUrl + "/cos/get_credential",
    method: "POST"
}
