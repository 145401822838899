import {Button, Popconfirm, Space, Tag} from "antd";
import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";

import React from "react";
import {ColumnsType} from "antd/es/table";
import {AnnouncementListItem} from "../../../model/announcement";

interface launchAnnouncementColumnProps {
    onWithdraw: (aid: number) => void
    onPublish: (aid: number) => void
    onChange: (record: AnnouncementListItem) => void
    onDelete: (aid: number) => void
    showDetail: boolean
}

export const launchAnnouncementColumn:
    (props: launchAnnouncementColumnProps) => ColumnsType<AnnouncementListItem>
    = (props) => {
    const column: ColumnsType<AnnouncementListItem> = [
        {
            title: "公告id",
            dataIndex: "Aid",
            align: "center",
            width: "10%"
        },
        {
            title: "标题",
            dataIndex: "Title",
            align: "center",
            width: "20%",
        },
        {
            title: "活动开始日期",
            dataIndex: "StartDate",
            align: "center",
            responsive: ['xl'],
            render: (startDate: string) => (
                <>
                    {startDate === '0000-00-00' ? '无' : startDate}
                </>
            )
        },
        {
            title: "活动结束时间",
            dataIndex: "EndDate",
            align: "center",
            responsive: ['xl'],
            render: (endDate: string) => (
                <>
                    {endDate === '0000-00-00' ? '无' : endDate}
                </>)
        },
        {
            title: "状态",
            dataIndex: "IsPublished",
            align: "center",
            render: (isPublished: boolean) => (
                <>
                    {
                        isPublished ?
                            <Tag icon={<CheckCircleOutlined />} color={"success"}>已发布</Tag> :
                            <Tag icon={<ClockCircleOutlined />} color={"processing"}>待发布</Tag>
                    }
                </>
            )
        },
        {
            title: "操作",
            key: "Action",
            align: "center",
            width: "25%",
            render: (_: any, record) => {
                return (
                    <Space>
                        {
                            record.IsPublished ?
                                <Button type={"link"}
                                        onClick={() => props.onWithdraw(record.Aid)}>
                                    撤回
                                </Button>
                                :
                                <Button type={"link"}
                                        onClick={() => props.onPublish(record.Aid)}>
                                    发布
                                </Button>
                        }
                        <Button type={"link"}
                                onClick={
                                    () => props.onChange(record)
                                }
                        >
                            修改
                        </Button>
                        <Popconfirm title={"是否确定要删除该公告？"}
                                    onConfirm={() => props.onDelete(record.Aid)}
                                    okText={"确定"}
                                    cancelText={"取消"}>
                            <Button type={"link"}>删除</Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    if (props.showDetail) {
        column.splice(4, 0, {
            title: "最后更新时间",
            dataIndex: "UpdateDate",
            align: "center",
        })
    }

    return column
}