import {Button, Col, Row, Typography, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import React from "react";
import {imgUploadToServerProps} from "../utils/tools";
import ImgCrop from "antd-img-crop";
import {uploadBannerCoverApi} from "../utils/api";


const {Text} = Typography

interface UploadBannerCoverProps {
    setCoverPath: (path: string) => void
}

export const UploadBannerCover: React.FC<UploadBannerCoverProps> = (props) => {
    const uploadProps = imgUploadToServerProps('cover', uploadBannerCoverApi.url,
        (info) => props.setCoverPath(info.file.response.Path))

    return (
        <Row justify={"space-around"} align={"middle"}>
            <Col span={15} style={{textAlign: "center"}}>
                <div><Text type={"secondary"}>请上传分辨率为750*400的图像(推荐)</Text></div>
                <Text type={"secondary"}>或比例为15：8的图像作为封面</Text>
            </Col>
            <Col span={4}>
                <ImgCrop aspect={750/400}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>
                            上传图片
                        </Button>
                    </Upload>
                </ImgCrop>
            </Col>
        </Row>
    )
}