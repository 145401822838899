import React from 'react';
import {Modal, Button, Avatar, Image, Space, Typography, message, Collapse, Input} from 'antd';
import {IdcardOutlined, KeyOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import {UserInfo, UserInfoManager} from "../../model/userInfo";
import {changeUserInfoApi, remoteHost} from "../../utils/api";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../utils/tools";
import {UploadAvatar} from "./uploadAvatar";

const {Text} = Typography
const { Panel } = Collapse;

interface ChangeUserInfoModalProps {
    open: boolean
    onCancel: () => void
    onUserInfoChange: (newInfo: UserInfo) => void
    setAvatar: (path: string) => void
}

export const ChangeUserInfoModal: React.FC<ChangeUserInfoModalProps> = (props) => {
    const newUserInfo = {
        NewNickName: UserInfoManager.statue.info.username,
        NewPassword: UserInfoManager.statue.info.password
    }

    const submitChange = async () => {
        let res = await handleWebsiteRequest(changeUserInfoApi, newUserInfo)

        await handleWebsiteResponse(res, (_) => {
            message.success("修改成功")
            UserInfoManager.statue.info.groupName = newUserInfo.NewNickName
            UserInfoManager.statue.info.password = newUserInfo.NewPassword
            UserInfoManager.save()
            props.onUserInfoChange(UserInfoManager.statue.info)
        }, (_) => {
            message.warning("操作异常，请检查后重试")
        }, (_) => {
            message.error("服务器异常，请联系管理员解决")
        })
    }

    return (
        <Modal
            open={props.open}
            title={"修改组织信息"}
            centered={true}
            onCancel={props.onCancel}
            footer={
                <Button onClick={props.onCancel}>关闭</Button>
            }
        >
            <Space direction={"vertical"} size={"middle"} align={"center"} style={{margin: "auto", width: "99%"}}>
                <Avatar size={150} src={<Image
                    src={remoteHost + UserInfoManager.statue.info.avatarUrl}
                    style={{width: 150}}
                    preview={false}/>
                }
                />
                <Text>组织名</Text>
                <Text strong>{UserInfoManager.statue.info.groupName}</Text>
                <UploadAvatar setAvatar={props.setAvatar} />
            </Space>
            <Collapse className={"changeUserInfo"} bordered={false} style={{marginTop: 24, width: '100%'}}>
                <Panel key={'1'} header={
                    <Space>
                        <IdcardOutlined />修改组织名
                    </Space>
                }>
                    <Space direction={"vertical"} style={{width: '100%'}}>
                        <Input placeholder="请输入新组织名" prefix={<UserOutlined />}
                               onChange={(e) => newUserInfo.NewNickName = e.target.value} />
                        <Button type={"primary"} onClick={submitChange}>确定</Button>
                    </Space>
                </Panel>
                <Panel key={'2'} header={
                    <Space>
                        <KeyOutlined />修改密码
                    </Space>
                }>
                    <Space direction={"vertical"}  style={{width: '100%'}}>
                        <Input.Password placeholder="请输入新密码" prefix={<LockOutlined />}
                                        onChange={(e) => newUserInfo.NewPassword = e.target.value} />
                        <Button type={"primary"} onClick={submitChange}>确定</Button>
                    </Space>
                </Panel>
            </Collapse>
        </Modal>
    )
}