import {ColumnsType} from "antd/es/table";
import {RecommendApplyListItem} from "../../../model/recommendApply";
import {Button, Space, Tag} from "antd";
import {RecommendPositionToText} from "../../../model/config";
import React from "react";
import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";

interface LaunchExamineApplyColumnProps {
    onClicked: (apply: RecommendApplyListItem) => void
}

export const launchExamineApplyColumn:
    (props: LaunchExamineApplyColumnProps) => ColumnsType<RecommendApplyListItem> = (props) => {
    const column: ColumnsType<RecommendApplyListItem> = [
        {
            title: "申请id",
            dataIndex: "RAid",
            align: "center",
            width: "8%",
        },
        {
            title: "组织名称",
            dataIndex: "GroupName"  ,
            align: "center",
        },
        {
            title: "活动标题",
            dataIndex: "Title",
            align: "center",
        },
        {
            title: "推荐起始日期",
            dataIndex: "StartDate",
            align: "center",
            width: "12%"
        },
        {
            title: "推荐结束日期",
            dataIndex: "EndDate",
            align: "center",
            width: "12%"
        },
        {
            title: "推荐位位置",
            dataIndex: "Position",
            align: "center",
            width: "10%",
            render: (position: number) => <Tag color={"blue"}>{RecommendPositionToText[position]}</Tag>
        },
        {
            title: "申请状态",
            dataIndex: "State",
            align: "center",
            width: "10%",
            render: (state: number) => {
                if (state === -1) {
                    return (
                        <Tag color={"default"} icon={<ClockCircleOutlined />}>待审核</Tag>
                    )
                } else if (state === 0) {
                    return (
                        <Tag color={"error"} icon={<ClockCircleOutlined />}>已拒绝</Tag>
                    )
                } else if (state === 1) {
                    return (
                        <Tag color={"success"} icon={<CheckCircleOutlined />}>已通过</Tag>
                    )
                }
            }
        },
        {
            title: "操作",
            key: "Action",
            align: "center",
            render: (_, record) => {
                return (
                    <Space>
                        <Button
                            type={"link"}
                            onClick={() => props.onClicked(record)}
                        >
                            审核
                        </Button>
                    </Space>
                )
            }
        },
    ]

    return column
}