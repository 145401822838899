import {Button, Card, Space} from "antd";
import {MenuOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

interface TableCardProps {
    title: string
    onAdd: () => void
    addButtonText: React.ReactNode
    moreHref: string
    table: React.ReactNode
}

export const TableCard: React.FC<TableCardProps> = (props) => {
    return (
        <Card title={props.title}
              extra={
                  <Space>
                      <Button icon={<PlusOutlined />}
                              onClick={props.onAdd}
                      >
                          {props.addButtonText}
                      </Button>
                      <Button disabled icon={<MenuOutlined />} href={props.moreHref}>更多</Button>
                  </Space>
              }
              style={{minHeight: '800px'}} bodyStyle={{padding: '0px'}}>
            {props.table}
        </Card>
    )
}