import {handleWebsiteRequest, handleWebsiteResponse} from "../utils/tools";
import {getAnnouncementApi} from "../utils/api";
import {message} from "antd";

export interface AnnouncementListItem {
    key: number
    Aid: number
    Title: string
    StartDate: string
    EndDate: string
    UpdateDate: string
    IsPublished: boolean
    CoverUrl: string
}

export interface AnnouncementData {
    title: string | null,
    type: "update" | "add"
    aid?: number
    startDate?: string | null,
    endDate?: string | null,
    coverUrl?: string | null,
}

export interface SendAnnouncementParams {
    Title: string
    StartDate: string
    EndDate: string
    CoverUrl: string
    Markdown: string
    Id?: number
    IsDelete?: boolean
}

export const getAnnouncement = async (id: number, onSuccess: (markdown: string) => void) => {
    let res = await handleWebsiteRequest(getAnnouncementApi, {'Id': id})

    await handleWebsiteResponse(res, (response) => {
        onSuccess(response.Markdown)
    }, (_) => {
        message.warning('无权访问')
    }, (_) => {
        message.error('服务器异常')
    })
}