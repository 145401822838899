import React from "react";
import {Card, Col, Row, Input, Spin} from "antd";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { LoadingOutlined } from '@ant-design/icons';

const {TextArea} = Input

interface MarkdownPreviewProps {
    inputText: string
    isLoading?: boolean
    onInputTextChange: (target: any) => void
}

export const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const MarkdownPreview: React.FC<MarkdownPreviewProps> = (props) => {
    return (
        <Row gutter={16}>
            <Col span={12}>
                <Spin tip={"加载中..."} spinning={props.isLoading ?? false} indicator={loadingIcon}>
                    <TextArea
                        showCount autoSize={{minRows: 10}} size={"large"}
                        value={props.inputText} onChange={(e) => props.onInputTextChange(e.target.value)}
                    />
                </Spin>
            </Col>
            <Col span={12}>
                <Card className={"markdownShowCard"} style={{height: 'calc(100% - 22px)'}}>
                    <Spin tip={"加载中..."} spinning={props.isLoading ?? false} indicator={loadingIcon}>
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]} children={props.inputText}
                        />
                    </Spin>
                </Card>
            </Col>
        </Row>
    );
}