import React, {useState} from "react";
import {TableCard} from "../components/tableComponents/tableCard";
import {AnnouncementTable} from "../components/tableComponents/announcementTableComponents/announcementTable";
import {useMyOutletContext} from "./basePage";
import {AddNewAnnouncementModal} from "../components/modalComponents/addNewAnnouncementModal";
import {Col, message, Row} from "antd";
import {ApplyTable} from "../components/tableComponents/applyTableComponents/applyTable";
import {AddNewApplyModal} from "../components/modalComponents/addNewApplyModal";

export const HomePage: React.FC = (props) => {
    const {userStatue: [userStatue]} = useMyOutletContext()
    const [forceRefreshApplies, setForceRefreshApplies] = useState(false)
    const [showAddAnnouncementModal, setShowAddAnnouncementModal] = useState(false)
    const [showAddReplyModal, setShowAddReplyModal] = useState(false)

    const onClickedAddAnnouncement = () => {
        if (userStatue.isLogin) {
            setShowAddAnnouncementModal(true)
        } else {
            message.warning('请登陆后再尝试操作')
        }
    }

    const onClickedAddApply = () => {
        if (userStatue.isLogin) {
            setShowAddReplyModal(true)
        } else {
            message.warning('请登陆后再尝试操作')
        }
    }

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <TableCard
                        title={"我的活动公告"}
                        addButtonText={"创建新公告"}
                        onAdd={onClickedAddAnnouncement}
                        table={
                            <AnnouncementTable
                                onAdd={onClickedAddAnnouncement}
                            />}
                        moreHref={""}
                    />
                </Col>
                <Col span={24} md={12}>
                    <TableCard
                        title={"推荐位申请"}
                        addButtonText={"创建新申请"}
                        onAdd={onClickedAddApply}
                        table={
                            <ApplyTable
                                onAdd={onClickedAddApply}
                                forceRefresh={forceRefreshApplies}
                            />}
                        moreHref={""}
                    />
                </Col>
            </Row>
            <AddNewAnnouncementModal visible={showAddAnnouncementModal} setVisible={setShowAddAnnouncementModal}/>
            <AddNewApplyModal visible={showAddReplyModal} setVisible={setShowAddReplyModal}
                              afterApply={() => setForceRefreshApplies((prevState) => !prevState)} />
        </>
    )
}