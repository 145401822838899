import React, {useCallback, useEffect, useState} from "react";
import {RecommendApplyListItem} from "../../model/recommendApply";
import {getAnnouncement} from "../../model/announcement";
import {Button, Card, Col, Divider, Image, message, Modal, Row, Space, Spin, Tag, Typography, Input} from "antd";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../utils/tools";
import {examineApplyApi, mediaUrl, MediaUrl} from "../../utils/api";
import {RecommendPositionToText} from "../../model/config";
import {CheckCircleOutlined, ClockCircleOutlined} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {loadingIcon} from "../markdownComponents/markdownPreview";
import {useMyOutletContext} from "../../pages/basePage";

const { TextArea } = Input;

interface ExamineApplyModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    applyInfo: RecommendApplyListItem | null,
    refreshApply: () => void
}

export const ExamineApplyModal: React.FC<ExamineApplyModalProps> = ({open, setOpen,
                                                                        applyInfo, refreshApply}) => {
    const {userStatue: [{isLogin}]} = useMyOutletContext()
    const [announcement, setAnnouncement] = useState("")
    const [comment, setComment] = useState<string | null>(null)
    const [markdownLoading, setMarkdownLoading] = useState(false)
    const [sendingExamineResult, setSendingExamineResult] = useState(false)

    const sendExamineResult = useCallback((state: number) => {
        if (!isLogin) {return;}
        if (applyInfo === null) {
            return
        }
        if (state === 0 && comment === null) {
            message.warning("请填写拒绝理由")
            return;
        }
        (
            async () => {
                setSendingExamineResult(true)
                let res = await handleWebsiteRequest(examineApplyApi, {State: state, Comment: comment, RAid: applyInfo.RAid})
                await handleWebsiteResponse(res, (data) => {
                    message.success("审核完成")
                }, () => {
                    message.warning("参数错误")
                }, () => {
                    message.error("服务器异常")
                })
                setSendingExamineResult(false)
                setOpen(false)
                refreshApply()
            }
        )()
    }, [applyInfo, comment])

    const getStateTag = (state: number) => {
        if (state === -1) {
            return (
                <Tag color={"default"} icon={<ClockCircleOutlined />}>待审核</Tag>
            )
        } else if (state === 0) {
            return (
                <Tag color={"error"} icon={<ClockCircleOutlined />}>已拒绝</Tag>
            )
        } else if (state === 1) {
            return (
                <Tag color={"success"} icon={<CheckCircleOutlined />}>已通过</Tag>
            )
        }
    }


    useEffect(() => {
        if (applyInfo !== null) {
            (
                async () => {
                    setMarkdownLoading(true)
                    await getAnnouncement(applyInfo.Aid, (markdown) => setAnnouncement(markdown))
                    setMarkdownLoading(false)
                }
            )()
        }
    }, [applyInfo])

    return (
        <Modal
            centered={true}
            title={"推荐申请审核"}
            open={open}
            onCancel={() => setOpen(false)}
            width={"40%"}
            footer={[
                <Button key={"cancel"} onClick={() => setOpen(false)}>
                    取消
                </Button>,
                <Button key={"accept"} type={"primary"} danger={true} loading={sendingExamineResult}
                        onClick={() => sendExamineResult(0)}>
                    拒绝
                </Button>,
                <Button key={"refuse"} type={"primary"} loading={sendingExamineResult}
                        onClick={() => sendExamineResult(1)} >
                    接受
                </Button>,
            ]}
        >
            <Divider orientation={"left"} style={{marginBottom: 8}}>公告信息</Divider>
            <Card>
                <Row justify={"space-between"}>
                    <Col span={8}>
                        <Space direction={"vertical"}>
                            <div>
                                <Typography.Text type={"secondary"}>标题：</Typography.Text>
                                <Typography.Text>{applyInfo?.Title}</Typography.Text>
                            </div>
                            <div>
                                <Typography.Text type={"secondary"}>组织名：</Typography.Text>
                                <Typography.Text>{applyInfo?.GroupName}</Typography.Text>
                            </div>
                            <Typography.Text type={"secondary"}>申请时段：</Typography.Text>
                            <Typography.Text>{applyInfo?.StartDate + " ~ " + applyInfo?.EndDate}</Typography.Text>
                            <Space>
                                <Tag color={"blue"}>{applyInfo !== null ? RecommendPositionToText[applyInfo.Position] : ""}</Tag>
                                {applyInfo !== null ? getStateTag(applyInfo.State) : ""}
                            </Space>
                        </Space>
                    </Col>
                    <Col span={12}>
                        <Image src={mediaUrl + MediaUrl.applyCover + applyInfo?.ImgPath} alt={"活动封面"} placeholder={true} />
                    </Col>
                </Row>
            </Card>

            <Divider orientation={"left"} style={{marginTop: 24, marginBottom: 8}}>公告内容</Divider>

            <Card className={"markdownShowCard"}>
                <Spin tip={"加载中..."} spinning={markdownLoading} indicator={loadingIcon}>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]} children={announcement}
                    />
                </Spin>
            </Card>

            <Divider orientation={"right"} style={{marginTop: 24, marginBottom: 8}}>审核意见</Divider>

            <TextArea
                showCount={true} autoSize={{ minRows: 4, maxRows: 8 }} allowClear={true} value={comment ?? ""}
                onChange={(event) => setComment(event.target.value)} />
        </Modal>
    )
}