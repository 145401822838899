import {Col, DatePicker, message, Modal, Row, Select, Space} from "antd";

import React, {useCallback} from "react";

import moment from "moment";
import {UploadBannerCover} from "../uploadBannerCover";
import {RecommendApplyParams} from "../../model/recommendApply";
import {useMyOutletContext} from "../../pages/basePage";
import {RecommendPositionToText} from "../../model/config";
import {handleWebsiteRequest, handleWebsiteResponse} from "../../utils/tools";
import {addRecommendApplyApi} from "../../utils/api";

const {RangePicker} = DatePicker
const {Option} = Select;

interface AddNewApplyModalProps {
    visible: boolean,
    afterApply: () => void
    setVisible: (value: boolean) => void
}

export const AddNewApplyModal: React.FC<AddNewApplyModalProps> = (props) => {
    const {userStatue: [userStatue,], announcementList: [announcements,]} = useMyOutletContext()

    const ApplyDate: RecommendApplyParams = {
        Aid: null,
        Position: null,
        StartDate: null,
        EndDate: null,
        Path: null
    }

    const handleOk = useCallback(async () => {
        if (ApplyDate.StartDate === null || ApplyDate.EndDate === null ||
            ApplyDate.Aid === null || ApplyDate.Position === null || ApplyDate.Path === null) {
            message.warning('存在未填项')
            return
        }

        if (userStatue.isLogin) {
            let res = await handleWebsiteRequest(addRecommendApplyApi, ApplyDate)

            await handleWebsiteResponse(res, (_) => {
                message.success('申请成功')
                props.setVisible(false)
                props.afterApply()
            }, (_) => {
                message.warning("参数错误")
            }, (_) => {
                message.warning("服务器异常")
            })
        }
    }, [ApplyDate])

    const handleDate = (date: any, dateString: [string, string]) => {
        if (date !== null) {
            ApplyDate.StartDate = dateString[0]
            ApplyDate.EndDate = dateString[1]
        }
    }

    const onChoosePosition = (value: string) => {
        ApplyDate.Position = parseInt(value)
    };

    const onChooseAnnouncement = (value: string | number) => {
        ApplyDate.Aid = typeof value === "string" ? parseInt(value) : value
    }

    return (
        <Modal title={"新建推荐位申请"}
               open={props.visible}
               onCancel={() => props.setVisible(false)}
               onOk={() => handleOk()}>
            <Space direction={"vertical"}>
                <Row justify={"space-around"} align={"middle"}>
                    <Col span={8}  style={{textAlign: 'center'}}>
                        <div>申请活动</div>
                    </Col>
                    <Col span={15}>
                        <Select
                            showSearch
                            allowClear
                            style={{width: '100%'}}
                            placeholder="选择活动公告"
                            optionFilterProp="children"
                            onSelect={onChooseAnnouncement}
                            filterOption={(inputValue, option) => {
                                return option?.searchkey.toLowerCase().includes(inputValue.toLowerCase())
                            }}
                        >
                            {announcements.map((announce) => {
                                return (
                                    <Option key={announce.key} searchkey={announce.Title+announce.Aid.toString()}>
                                        {
                                            <Space>
                                                <div>{announce.Title}[id: {announce.Aid}]</div>
                                            </Space>
                                        }
                                    </Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <Row justify={"space-around"} align={"middle"}>
                    <Col span={8}  style={{textAlign: 'center'}}>
                        <div>推荐开始、结束时间</div>
                    </Col>
                    <Col span={15}>
                        <RangePicker
                            style={{width: '100%'}}
                            disabledDate={(current) => current && current < moment().endOf('day')}
                            onChange={(date, dateString) => handleDate(date, dateString)} />
                    </Col>
                </Row>
                <Row justify={"space-around"} align={"middle"}>
                    <Col span={8}  style={{textAlign: 'center'}}>
                        <div>推荐展示位置</div>
                    </Col>
                    <Col span={15}>
                        <Select
                            showSearch
                            allowClear
                            placeholder="选择推荐展示位置"
                            optionFilterProp="children"
                            onSelect={onChoosePosition}
                            style={{width: '100%'}}
                        >
                            {Object.keys(RecommendPositionToText).map((value) => {
                                return (
                                    <Option key={value}>
                                        {RecommendPositionToText[value]}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Col>
                </Row>
                <UploadBannerCover setCoverPath={(path) => ApplyDate.Path = path} />
            </Space>
        </Modal>
    )
}