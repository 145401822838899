import {handleWebsiteRequest, handleWebsiteResponse} from "../utils/tools";
import {getAuthorityApi} from "../utils/api";

export interface UserInfo {
    username: string | null
    password: string | null
    authority: null | "super" | "normal" | "auditor"
    groupName: string | null
    avatarUrl: string | null
    save: () => void
    clear: () => void
}

export interface UserStatue{
    info: UserInfo
    isLogin: boolean
    save: () => void
    clear: () => void
}

let userInfo: UserInfo = {
    username: localStorage.getItem('username'),
    password: localStorage.getItem('password'),
    authority: null,
    groupName: localStorage.getItem('groupName'),
    avatarUrl: localStorage.getItem('avatarUrl'),
    save: () => {
        if (userInfo.username !== null) {
            localStorage.setItem('username', userInfo.username)
        }
        if (userInfo.password !== null) {
            localStorage.setItem('password', userInfo.password)
        }
        if (userInfo.groupName !== null) {
            localStorage.setItem('groupName', userInfo.groupName)
        }
        if (userInfo.avatarUrl !== null) {
            localStorage.setItem('avatarUrl', userInfo.avatarUrl)
        }
    },

    clear: () => {
        localStorage.removeItem('username')
        localStorage.removeItem('password')
        localStorage.removeItem('groupName')
        localStorage.removeItem('avatarUrl')
        userInfo.username = null
        userInfo.password = null
        userInfo.groupName = null
        userInfo.avatarUrl = null
    }
}

let userStatue: UserStatue = {
    info: userInfo,
    isLogin: (localStorage.getItem('isLogin') ?? 'false') === 'true',
    save: () => {
        userStatue.info.save()
        localStorage.setItem('isLogin', String(userStatue.isLogin))
    },
    clear: () => {
        localStorage.removeItem('isLogin')
        userStatue.info.clear()
        userStatue.isLogin = false
    }
}

export const UserInfoManager = {
    statue: userStatue,
    save: () => {
        UserInfoManager.statue.save()
    },
    logout() {
        UserInfoManager.statue.clear()
    }
}

export const getAuthority = async (onSuccess: (authority: string) => void) => {
    if (userStatue.info) {
        let res = await handleWebsiteRequest(getAuthorityApi, {})
        await handleWebsiteResponse(res, (data) => {
            UserInfoManager.statue.info.authority = data.Data.Authority
            onSuccess(data.Data.Authority)
        })
    }
}