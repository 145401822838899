import {imgUploadToServerProps} from "../../utils/tools";
import {changeAvatarApi} from "../../utils/api";
import {Button,Upload} from "antd";
import ImgCrop from "antd-img-crop";
import {PictureOutlined} from "@ant-design/icons";
import React from "react";

export const UploadAvatar: React.FC<{setAvatar: (path: string) => void}> = (props) => {
    const uploadProps = imgUploadToServerProps('avatar', changeAvatarApi.url,
        (info) => props.setAvatar(info.file.response.Path))
    return (
        <ImgCrop>
            <Upload {...uploadProps}>
                <Button icon={<PictureOutlined />}>
                    修改头像
                </Button>
            </Upload>
        </ImgCrop>
    )
}