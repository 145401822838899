import React from "react";
import {Button, Modal} from "antd";
import {LoginForm} from "./LoginForm";

interface LoginButtonProps {
    afterLogin: () => void
}

export const LoginButton: React.FC<LoginButtonProps> = ({afterLogin}) => {
    const [visible, setVisible] = React.useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleLogin = () => {
        setVisible(false)
        afterLogin()
    };

    return (
        <>
            <Button type="primary" onClick={showModal} shape={"round"} style={{marginLeft: "auto"}}>
                登陆
            </Button>
            <Modal
                title="登陆"
                open={visible}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                <LoginForm loginSuccess={handleLogin}/>
            </Modal>
        </>
    );
}