import {handleWebsiteRequest, handleWebsiteResponse} from "./tools";
import {getCosCredential} from "./api";
import {GetAuthorizationOptions} from "cos-js-sdk-v5";
const COS = require("cos-js-sdk-v5");

export const launchCos = () => {
    return new COS({
        getAuthorization: function (options: GetAuthorizationOptions, callback: any) {
            (
                async () => {
                    let res = await handleWebsiteRequest(getCosCredential, {Version: '2.0', Params: {Type: 'test'}}, false)

                    await handleWebsiteResponse(res, (response) => {
                        callback({
                            TmpSecretId: response.data.Credentials.TmpSecretId,
                            TmpSecretKey: response.data.Credentials.TmpSecretKey,
                            SecurityToken: response.data.Credentials.SessionToken,
                            ExpiredTime: response.data.ExpiredTime,
                        })
                    })
                }
            )();
        }
    });
}

export const defaultCos = launchCos();